/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {ScopeType} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problem/types';
import {ProblemFinderRegistration} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/registration/types';
import {ProblemFinderSchedule, ScheduleScope} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/scheduler';
import Alert from '@amzn/meridian/alert';
import {AlertProps} from '@amzn/meridian/alert/alert';
import Box from '@amzn/meridian/box';
import Breadcrumb, {BreadcrumbGroup} from '@amzn/meridian/breadcrumb';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import cron from 'cron-validate';
import _ from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';

import {AppContext} from '../../../app';
import {useSearchSelect} from '../../../hooks/use-search-select';
import {selectSelectedRegion} from '../../../state/app/appSlice';
import {InputError} from '../../../types/input-error';
import {invokeLambda} from '../../../utility/aws-accessor';
import {getCronError} from '../../../utility/cron-utility';
import {
    getProblemFinderRegistration,
    listProblemFinderRegistrations,
    listProblemFinderSchedule,
    putProblemFinderRegistration,
    putProblemFinderSchedule
} from '../../../utility/id4-mothership-client';
import {validateId} from '../../../utility/validate-id';
import {CreationProps} from '../../Inputs/CreationProps';
import ScheduleScopeListInput from '../../Inputs/ScheduleScopeListInput';
import SchedulingInput from '../../Inputs/SchedulingInput';
import {RouteName} from '../../Routing/RoutingPage';
import {detailsReducer, KeyValueEdit} from '../../utility-views/KeyValueEdit';
import LoadingButton from '../../utility-views/LoadingButton';
import LoadingMessage from '../../utility-views/LoadingMessage';
import {EditResourceComponentType, EditResourceConfiguration} from '../Configurations/IPutResource';

const RegisterProblemFinder: React.FC<CreationProps> = ({inEditMode}) => {

    // This value is used as a delimiter when storing CTIs within the Mothership, so we'll filter out any CTI with it
    const NOT_ALLOWED_DELIMITER = '~';

    const {webStageConfig, stageConfiguration} = React.useContext(AppContext);

    const selectedRegion = useSelector(selectSelectedRegion);

    const navigate = useNavigate();

    const {problemFinderId} = useParams();

    const [inError, setInError] = useState(false);

    /**
     * Memoized value for cron input errors using custom cron validation function
     */

    const [existingSchedule, setExistingSchedule] = React.useState<ProblemFinderSchedule>();
    const [existingRegistration, setExistingRegistration] = React.useState<ProblemFinderRegistration>();
    const [isLoadingSchedule, setIsLoadingSchedule] = React.useState<boolean>(inEditMode);

    const [currentRegistrations, setCurrentRegistrations] = useState<ProblemFinderRegistration[]>([]);
    const [loadingCurrentRegistrations, setLoadingCurrentRegistrations] = useState<boolean>(true);

    const [problemFinderIdToUse, setProblemFinderIdToUse] = useState<string>(inEditMode ? problemFinderId : undefined);

    const [ctiCategories, setCtiCategories] = useState<string[]>([]);
    const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true);

    const [ctiTypes, setCtiTypes] = useState<string[]>([]);
    const [typesLoading, setTypesLoading] = useState<boolean>(true);

    const [ctiItems, setCtiItems] = useState<string[]>([]);
    const [itemsLoading, setItemsLoading] = useState<boolean>(true);

    const [resolverGroups, setResolverGroups] = useState<string[]>([]);
    const [resolverGroupsLoading, setResolverGroupsLoading] = useState<boolean>(true);


    const [ctiCategory, setCtiCategory, CategorySearchSelect] = useSearchSelect({
        label: 'Category (C)',
        placeholder: 'Select category',
        options: ctiCategories.map(category => ({label: category, value: category})),
        isLoading: categoriesLoading,
        loadingMessage: categoriesLoading && 'Loading categories...',
    });
    const [ctiType, setCtiType, TypeSearchSelect] = useSearchSelect({
        label: 'Type (T)',
        placeholder: 'Select type',
        options: ctiTypes.map(type => ({label: type, value: type})),
        isLoading: typesLoading,
        loadingMessage: 'Loading types...',
        disabled: ctiCategory === undefined,
        constraintText: ctiCategory === undefined ? 'Select a Category (C) first' : (typesLoading && 'Loading types...')
    });
    const [ctiItem, setCtiItem, ItemSearchSelect] = useSearchSelect({
        label: 'Item (I)',
        placeholder: 'Select item',
        options: ctiItems.map(item => ({label: item, value: item})),
        isLoading: itemsLoading,
        loadingMessage: 'Loading items...',
        disabled: ctiType === undefined,
        constraintText: ctiType === undefined ? 'Select a Type (T) first' : (itemsLoading && 'Loading items...')
    });
    const [resolverGroup, setResolverGroup, ResolverGroupSearchSelect] = useSearchSelect({
        label: 'Resolver group',
        placeholder: 'Select resolver group',
        options: resolverGroups.map(rg => ({label: rg, value: rg})),
        isLoading: resolverGroupsLoading,
        loadingMessage: 'Loading resolver groups...',
        disabled: ctiItem === undefined,
        constraintText: ctiItem === undefined ? 'Select an Item (I) first' : (resolverGroupsLoading && 'Loading resolver groups...')
    });

    // Input label and helper text for problem finder registration details
    const inputDetails: Record<string, EditResourceConfiguration> = {
        domain: {
            helperText: 'The domain of this problem finder.',
            label: 'Domain *',
            componentType: EditResourceComponentType.TEXT_BOX
        },
        focus: {
            helperText: 'What types of problems does this problem finder focus on?',
            label: 'Focus *',
            componentType: EditResourceComponentType.TEXT_BOX
        },
        logic: {
            helperText: 'How does this problem finder find these problems.',
            label: 'Logic *',
            componentType: EditResourceComponentType.TEXT_BOX
        },
        scope: {
            helperText: 'Where does this problem finder look for problems.',
            label: 'Scope *',
            componentType: EditResourceComponentType.TEXT_BOX
        }
    };

    const initialDetails: any = {
        domain: undefined,
        focus: undefined,
        logic: undefined,
        scope: undefined
    };

    const [zoneStates, setZoneStatus, ZoneStatesSelect] = useSearchSelect({
        label: 'Zone states',
        placeholder: 'Select zone states',
        options: [
            {label: 'Deployment', value: 'DEPLOYMENT'},
            {label: 'Live', value: 'LIVE'},
            {label: 'Maintenance', value: 'MAINTENANCE'},
            {label: 'Decommissioned', value: 'DECOMISSIONED'}
        ],
        isLoading: false,
        loadingMessage: 'Loading zone states...',
        width: '100%',
        allowMultiSelect: true
    });

    const [problemFinderDetails, setProblemFinderDetails] = React.useReducer(detailsReducer, initialDetails);

    const [cronInput, setCronInput] = React.useState<string>(undefined);
    const cronError = useMemo(() => getCronError(cronInput), [cronInput]);
    const [enabled, setEnabled] = React.useState<boolean>(false);
    const [scope, setScope] = React.useState<ScheduleScope[]>([]);
    const [scopeType, setScopeType, ScopeTypeSelect] = useSearchSelect({
        label: 'Scope type',
        placeholder: 'Select scope type',
        options: Object.entries(ScopeType).map(([label, value]) => ({label, value})),
        isLoading: false,
        loadingMessage: 'Loading scope types...',
        width: '100%'
    });

    const [submitted, setSubmitted] = useState<boolean>(false);

    const [alertMessage, setAlertMessage] = useState<{
        type: AlertProps['type'],
        title?: AlertProps['title'],
        message: string,
        link?: { text: string, href: string }
    }>(undefined);

    /**
     * Effect which gets a list of CTI categories and gets a list of all current problem finder
     * registrations in order to formulate a list of IDs for uniqueness.
     */
    useEffect(() => {
        const fetchCategories = async () => {
            const categories = await getCategories();
            setCtiCategories(categories.filter(category => !category.includes(NOT_ALLOWED_DELIMITER)));
        };

        fetchCategories()
            .catch(err => {
                setAlertMessage({
                    type: 'error',
                    message: err.message,
                    title: 'Error loading CTI categories.'
                });
                console.error(err);
            })
            .finally(() => setCategoriesLoading(false));
    }, []);

    /**
     * Refreshes the current set of problem finder registrations based on the selected region.
     */
    useEffect(() => {
        // Initially clear the registrations when the region changes and set the state to loading
        setCurrentRegistrations([]);
        setLoadingCurrentRegistrations(true);

        const fetchProblemFinderRegistrations = async () => {
            const registrations = await listProblemFinderRegistrations(webStageConfig, selectedRegion);
            setCurrentRegistrations(registrations);
        };

        fetchProblemFinderRegistrations()
            .catch(err => {
                setAlertMessage({
                    type: 'error',
                    message: err.message,
                    title: 'Error loading known problem finder registrations.'
                });
                console.error(err);
            })
            .finally(() => setLoadingCurrentRegistrations(false));
    }, [selectedRegion]);

    /**
     * Effect which will preload the existing registration details if provided in the URL search params.
     */
    useEffect(() => {
        if (inEditMode) {
            const fetchProblemFinder = async () => {
                const problemFinderRegistration = await getProblemFinderRegistration(webStageConfig, selectedRegion, {
                    problemFinderId: problemFinderId
                });
                setExistingRegistration(problemFinderRegistration);
                // Setting default empty string values because these fields have been added after many established problem finders
                setProblemFinderDetails({
                    domain: problemFinderRegistration.domain || '',
                    focus: problemFinderRegistration.focus || '',
                    logic: problemFinderRegistration.logic || '',
                    scope: problemFinderRegistration.scope || ''
                });
            };

            const fetchSchedule = async () => {
                const problemFinderSchedules = await listProblemFinderSchedule(webStageConfig, selectedRegion);
                const scheduleForId = _.find(problemFinderSchedules, schedule => schedule.problemFinderId === problemFinderId);
                if (scheduleForId) {
                    setExistingSchedule(scheduleForId);
                }
            };

            fetchProblemFinder()
                .catch(err => {
                    setAlertMessage({
                        type: 'error',
                        message: err.message,
                        title: 'Error loading problem finder registration.'
                    });
                    console.error(err);
                });
            fetchSchedule()
                .catch(err => {
                    setAlertMessage({
                        type: 'error',
                        message: err.message,
                        title: 'Error loading problem finder schedules.'
                    });
                    console.error(err);
                })
                .finally(() => setIsLoadingSchedule(false));
        }
    }, [inEditMode]);

    /**
     * Effect which will set the existing problem finder registration category when the results are loaded in.
     */
    useEffect(() => {
        if (ctiCategories.length > 0 && existingRegistration) {
            setCtiCategory(existingRegistration.cti.category);
        }
    }, [ctiCategories, existingRegistration]);

    /**
     * Effect which will set the existing problem finder registration type when the results are loaded in.
     */
    useEffect(() => {
        if (ctiTypes.length > 0 && existingRegistration) {
            setCtiType(existingRegistration.cti.type);
        }
    }, [ctiTypes, existingRegistration]);

    /**
     * Effect which will set the existing problem finder registration item when the results are loaded in.
     */
    useEffect(() => {
        if (ctiItems.length > 0 && existingRegistration) {
            setCtiItem(existingRegistration.cti.item);
        }
    }, [ctiItems, existingRegistration]);

    /**
     * Effect which will set the existing problem finder registration resolver group when the results are loaded in.
     */
    useEffect(() => {
        if (resolverGroups.length > 0 && existingRegistration) {
            setResolverGroup(existingRegistration.cti.group);
        }
    }, [resolverGroups, existingRegistration]);

    /**
     * Effect which will set the existing problem finder schedule fields.
     */
    useEffect(() => {
        if (existingSchedule) {
            setCronInput(existingSchedule.scheduleCronExpression);
            setEnabled(existingSchedule.enabled);
            setScope(existingSchedule.scopeSet);
            setScopeType(existingSchedule.scopeType);
        }
    }, [existingSchedule]);

    /**
     * Effect which gets a list of CTI types.
     */
    useEffect(() => {
        if (ctiCategory) {
            setCtiType(undefined);
            setCtiItem(undefined);
            setResolverGroup(undefined);
            setTypesLoading(true);
            setItemsLoading(true);
            setResolverGroupsLoading(true);

            const fetchTypes = async () => {
                const types = await getTypes();
                setCtiTypes(types.filter(type => !type.includes(NOT_ALLOWED_DELIMITER)));
            };

            fetchTypes()
                .catch(err => {
                    setAlertMessage({
                        type: 'error',
                        message: err.message,
                        title: 'Error loading CTI types.'
                    });
                    console.error(err);
                })
                .finally(() => setTypesLoading(false));
        }
    }, [ctiCategory]);

    /**
     * Effect which gets a list of CTI items.
     */
    useEffect(() => {
        if (ctiType) {
            setCtiItem(undefined);
            setResolverGroup(undefined);
            setItemsLoading(true);
            setResolverGroupsLoading(true);

            const fetchItems = async () => {
                const items = await getItems();
                setCtiItems(items.filter(item => !item.includes(NOT_ALLOWED_DELIMITER)));
            };

            fetchItems()
                .catch(err => {
                    setAlertMessage({
                        type: 'error',
                        message: err.message,
                        title: 'Error loading CTI items.'
                    });
                    console.error(err);
                })
                .finally(() => setItemsLoading(false));
        }
    }, [ctiType]);

    /**
     * Effect which gets a list of resolver groups.
     */
    useEffect(() => {
        if (ctiItem) {
            setResolverGroup(undefined);

            const fetchResolverGroups = async () => {
                const groups = await getResolverGroups();
                setResolverGroups(groups.filter(group => !group.includes(NOT_ALLOWED_DELIMITER)));
            };

            fetchResolverGroups()
                .catch(err => {
                    setAlertMessage({
                        type: 'error',
                        message: err.message,
                        title: 'Error loading resolver groups.'
                    });
                    console.error(err);
                })
                .finally(() => setResolverGroupsLoading(false));
        }
    }, [ctiItem]);

    /**
     * Makes a request for a list of CTI categories.
     *
     * @returns {Promise<string[]>} the categories
     */
    async function getCategories(): Promise<string[]> {
        const response = await invokeLambda(stageConfiguration, stageConfiguration.lambda.ctiLambdaArn);
        if (response.statusCode === 200) {
            return _.uniq(JSON.parse(response.payload));
        } else {
            throw new Error(response.payload);
        }
    }

    /**
     * Makes a request for a list of CTI types using the selected category.
     *
     * @returns {Promise<string[]>} the types
     */
    async function getTypes(): Promise<string[]> {
        const response = await invokeLambda(stageConfiguration, stageConfiguration.lambda.ctiLambdaArn, {
            category: ctiCategory
        });
        if (response.statusCode === 200) {
            return _.uniq(JSON.parse(response.payload));
        } else {
            throw new Error(response.payload);
        }
    }

    /**
     * Makes a request for a list of CTI items using the selected category and type.
     *
     * @returns {Promise<string[]>} the items
     */
    async function getItems(): Promise<string[]> {
        const response = await invokeLambda(stageConfiguration, stageConfiguration.lambda.ctiLambdaArn, {
            category: ctiCategory,
            type: ctiType
        });
        if (response.statusCode === 200) {
            return _.uniq(JSON.parse(response.payload));
        } else {
            throw new Error(response.payload);
        }
    }

    /**
     * Makes a request for a list of resolver groups for a specific CTI.
     *
     * @returns {Promise<string[]>} the resolver groups
     */
    async function getResolverGroups(): Promise<string[]> {
        const response = await invokeLambda(stageConfiguration, stageConfiguration.lambda.ctiLambdaArn, {
            category: ctiCategory,
            type: ctiType,
            item: ctiItem
        });
        if (response.statusCode === 200) {
            return _.uniq(JSON.parse(response.payload));
        } else {
            throw new Error(response.payload);
        }
    }

    /**
     * Determines if the problem finder ID is unique.
     *
     * @returns {boolean} is the problem finder ID unique
     */
    function isProblemFinderIdUnique(): boolean {
        // TODO: will this scale? Can we have an API to say 'is problem finder ID unique?'
        const allIds = currentRegistrations.map(reg => reg.problemFinderId);
        return !allIds.includes(problemFinderIdToUse);
    }

    /**
     * Evaluates the problem finder ID input.
     *
     * @returns {InputError} error and message
     */
    function evaluateProblemFinderInput(): InputError {
        if (inEditMode) {
            return {
                error: false,
                errorMessage: ''
            };
        }

        if (problemFinderIdToUse === '') {
            return {
                error: true,
                errorMessage: 'Identifier cannot be empty'
            };
        } else if (problemFinderIdToUse?.length > 64) {
            return {
                error: true,
                errorMessage: 'Identifier must be less than 64 characters'
            };
        } else if (problemFinderIdToUse && !validateId(problemFinderIdToUse)) {
            return {
                error: true,
                errorMessage: 'Identifier contains a disallowed character'
            };
        } else if (!isProblemFinderIdUnique()) {
            return {
                error: true,
                errorMessage: `'${problemFinderIdToUse}' is already registered in the ID4 system. Choose another identifier`
            };
        } else {
            return {
                error: false,
                errorMessage: ''
            };
        }
    }

    /**
     * When the registration is submitted, POST the details to ID4.
     */
    function onSubmit(): void {
        setSubmitted(true);
        const cti = {
            category: ctiCategory as string,
            type: ctiType as string,
            item: ctiItem as string,
        };

        putProblemFinderRegistration(webStageConfig, selectedRegion, {
            problemFinderId: problemFinderIdToUse,
            registrationDetails: {
                cti: resolverGroup ? {...cti, group: resolverGroup as string} : cti,
                ...problemFinderDetails
            }
        })
            .then(registrationResponse => {

                if (!!scope.length && scopeType) {
                    // After successfully putting the registration, put the schedule as well
                    let zoneStateSet;
                    if (zoneStates === null) {
                        zoneStateSet = [];
                    } else if (typeof zoneStates === 'string') {
                        zoneStateSet = [zoneStates]; // Convert single string to array
                    } else {
                        zoneStateSet = zoneStates; // Use the array as-is
                    }
                    putProblemFinderSchedule(webStageConfig, selectedRegion, {
                        problemFinderScheduleId: existingSchedule ? existingSchedule.problemFinderScheduleId : uuidv4(),
                        problemFinderScheduleDetails: {
                            scheduleCronExpression: cronInput,
                            problemFinderId: problemFinderIdToUse,
                            enabled,
                            scopeSet: scope,
                            scopeType: scopeType as ScopeType,
                            zoneStateSet: zoneStateSet
                        }
                    })
                        .then(() => {
                            // Then set the success message
                            setAlertMessage({
                                type: 'success',
                                message: `Successfully registered and scheduled problem finder: `,
                                link: {
                                    text: registrationResponse.registration.problemFinderId,
                                    href: `${RouteName.PROBLEM_FINDERS}/${registrationResponse.registration.problemFinderId}`
                                }
                            });

                            // Add the new registration to the list of known registrations
                            setCurrentRegistrations(prevState => [...prevState, registrationResponse.registration]);
                            setSubmitted(false);
                        })
                        .catch(err => {
                            setAlertMessage({
                                type: 'error',
                                message: err.message,
                                title: 'Error scheduling problem finder'
                            });
                            setSubmitted(false);
                        });
                } else {
                    setAlertMessage({
                        type: 'success',
                        message: `Successfully registered problem finder: `,
                        link: {
                            text: registrationResponse.registration.problemFinderId,
                            href: `${RouteName.PROBLEM_FINDERS}/${registrationResponse.registration.problemFinderId}`
                        }
                    });
                    setSubmitted(false);
                }
            })
            .catch(err => {
                setAlertMessage({
                    type: 'error',
                    message: err.message,
                    title: 'Error registering problem finder'
                });
                setSubmitted(false);
            });

    }

    /**
     * Are all the required inputs filled out to register a problem finder?
     *
     * @returns {boolean} true if all the required inputs filled out to register a problem finder
     */
    function isRegistrationFormComplete(): boolean {
        const problemFinderIdError = !problemFinderIdToUse || evaluateProblemFinderInput().error;
        const ctiError = ctiCategory === undefined || ctiType === undefined || ctiItem === undefined;
        const scheduleError = (cronInput ? !cron(cronInput).isValid() : false)
            || (scopeType === undefined && (scope !== undefined && !!scope.length))
            || (scopeType !== undefined && ((scope === undefined || scope.length === 0)))
            || (scope ? scope.some(scope => scope.scope === undefined || scope.clusivity === undefined) : false);
        const problemFinderDetailsError = Object.values(problemFinderDetails).some(detail => !detail);
        return !problemFinderIdError && !ctiError && !scheduleError && !problemFinderDetailsError;
    }

    /**
     * Clears the registration input state.
     */
    function clearRegistrationInputs(): void {
        setProblemFinderIdToUse(undefined);
        setSubmitted(false);
        clearCti();
        setProblemFinderDetails(initialDetails);
        setCronInput(undefined);
        setEnabled(false);
        setScope(undefined);
        setScopeType(undefined);
    }

    /**
     * Clears the CTI input state.
     */
    function clearCti(): void {
        setCtiCategory(undefined);
        setCtiType(undefined);
        setCtiItem(undefined);
        setResolverGroup(undefined);
    }

    /**
     * Gets the problem finder ID input helper text.
     * @returns {string} helper text
     */
    function getProblemFinderIdInputHelperText(): string {
        if (loadingCurrentRegistrations) {
            return 'Loading existing ID4 configurations';
        } else if (inEditMode) {
            return 'The problem finder identifier cannot be changed';
        } else {
            return 'Enter a unique identifier for your problem finder';
        }
    }

    return (
        <Column width='100%'>
            <BreadcrumbGroup>
                <Breadcrumb href={RouteName.PROBLEM_FINDERS}>Problem Finders</Breadcrumb>
                <Breadcrumb>{inEditMode ? problemFinderIdToUse : 'Create'}</Breadcrumb>
            </BreadcrumbGroup>
            {
                alertMessage && (
                    <Alert
                        type={alertMessage.type}
                        title={alertMessage.title}
                        onClose={() => {
                            setAlertMessage(undefined);
                            setSubmitted(false);
                        }}
                    >
                        {alertMessage.message}
                        {
                            alertMessage.link && (
                                <Link onClick={() => navigate(alertMessage.link.href)}>{alertMessage.link.text}</Link>
                            )
                        }
                    </Alert>
                )
            }
            {
                inEditMode && (
                    <Link href={RouteName.PROBLEM_FINDERS + '/create'}>
                        Not looking to update an existing problem finder? Click here to create one instead.
                    </Link>
                )
            }
            <Column>
                <Box type='outline' spacingInset='400'>
                    <Column width='100%'>
                        <Text type='h300'>Identification</Text>
                        <Input
                            label='Problem Finder Name'
                            id='problem-finder-id-input'
                            value={problemFinderIdToUse}
                            onChange={setProblemFinderIdToUse}
                            type='text'
                            helperText={getProblemFinderIdInputHelperText()}
                            width='100%'
                            {...evaluateProblemFinderInput()}
                            disabled={inEditMode || loadingCurrentRegistrations}
                        />
                        {CategorySearchSelect}
                        {TypeSearchSelect}
                        {ItemSearchSelect}
                        {ResolverGroupSearchSelect}
                        <KeyValueEdit
                            inEditMode={inEditMode}
                            details={problemFinderDetails}
                            setDetails={setProblemFinderDetails}
                            editConfiguration={inputDetails}
                            onError={setInError}/>
                    </Column>
                </Box>
                <Row alignmentVertical='stretch' widths='fill' spacing='500' wrap='down'>
                    <Box type='outline' spacingInset='400' minWidth={400}>
                        <Column width='75%' alignmentHorizontal='start'>
                            <Text type='h300'>Scope</Text>
                            {ScopeTypeSelect}
                            {ZoneStatesSelect}
                            <ScheduleScopeListInput scheduleScopeValue={scope} setScheduleScopeValue={setScope}/>
                        </Column>
                    </Box>
                    <Box type='outline' spacingInset='400' minWidth={400}>
                        {isLoadingSchedule ? (
                            <LoadingMessage message='Loading schedule...' size={'small'}/>
                        ) : (
                            <ProblemFinderScheduleInput
                                setCronExpression={setCronInput}
                                cronExpression={cronInput}
                                cronError={cronError}
                                setEnabled={setEnabled}
                                enabled={enabled}
                            />
                        )}
                    </Box>
                </Row>
                <Row alignmentHorizontal='justify' width='100%'>
                    <LoadingButton
                        text={!inEditMode ? 'Register' : 'Update'}
                        isLoading={submitted}
                        disabled={!isRegistrationFormComplete() || submitted}
                        onClick={onSubmit}
                    />
                    <Button type='secondary' onClick={clearCti}>
                        Reset CTI
                    </Button>
                </Row>
            </Column>
        </Column>
    );
};

const ProblemFinderScheduleInput = ({
    cronExpression, cronError, setCronExpression, enabled, setEnabled
}: {
    cronExpression: string, setCronExpression: React.Dispatch<React.SetStateAction<string>>,
    cronError: string | null,
    enabled: boolean, setEnabled: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    return (
        <Column alignmentHorizontal='start'>
            <SchedulingInput
                enabled={enabled}
                setEnabled={setEnabled}
                cronError={cronError}
                cronExpression={cronExpression}
                setCronExpression={setCronExpression}
                enabledText='Enable problem finder?'
                secondaryText='Select a fixed period to run the problem finder. Alternatively, define your own Cron expression.'
            />
        </Column>
    );
};

export default RegisterProblemFinder;
