/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Column from '@amzn/meridian/column';
import React, {useMemo} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';

import Dashboard from '../Dashboard/Dashboard';
import {OverridesResourceConfiguration} from '../Resources/Configurations/impl/OverridesResourceConfiguration';
import {ProblemFinderResourceConfiguration} from '../Resources/Configurations/impl/ProblemFinderResourceConfiguration';
import {ProblemResourceConfig} from '../Resources/Configurations/impl/ProblemResourceConfiguration';
import {ProblemTypeResourceConfig} from '../Resources/Configurations/impl/ProblemTypeResourceConfiguration';
import {ReportConfigResourceConfig} from '../Resources/Configurations/impl/ReportConfigurationsResourceConfiguration';
import {WebhookResourceConfig} from '../Resources/Configurations/impl/WebhookResourceConfiguration';
import {WebhookSetResourceConfig} from '../Resources/Configurations/impl/WebhookSetResourceConfiguration';
import PutResourceComponent from '../Resources/General/PutResourceComponent';
import TableResourceComponent from '../Resources/General/TableResourceComponent';
import {ViewResourceComponent} from '../Resources/General/ViewResourceComponent';
import PutProblemFinder from '../Resources/ProblemFinders/RegisterProblemFinder';
import PutReportConfiguration from '../Resources/ReportConfigurations/CreateReportConfiguration';
import Page401 from './Page401';
import Page404 from './Page404';

export enum RouteName {
    DASHBOARD = '/',
    PROBLEM_FINDERS = '/problemFinders',
    REPORT_CONFIGURATIONS = '/reportConfigurations',
    PROBLEM_TYPES = '/problemTypeDefinitions',
    OVERRIDES = '/overrides',
    WEBHOOKS = '/webhooks',
    WEBHOOK_SETS = '/webhookSets',
    PROBLEMS = '/problems',
}

const maxWidth = 1440;

const RoutingPage = () => {

    const navigate = useNavigate();
    const problemFinders = useMemo(() => new ProblemFinderResourceConfiguration(navigate), []);
    const reportConfigurations = useMemo(() => new ReportConfigResourceConfig(navigate), []);
    const problemTypes = useMemo(() => new ProblemTypeResourceConfig(navigate), []);
    const overrides = useMemo(() => new OverridesResourceConfiguration(navigate), []);
    const problems = useMemo(() => new ProblemResourceConfig(navigate), []);
    const webhooks = useMemo(() => new WebhookResourceConfig(navigate), []);
    const webhookSet = useMemo(() => new WebhookSetResourceConfig(navigate), []);

    return (
        <Routes>
            <Route path={RouteName.DASHBOARD} element={
                <Column spacingInset='500'>
                    <Dashboard/>
                </Column>
            }/>
            <Route path='/401' element={
                <Column spacingInset='500'>
                    <Page401/>
                </Column>
            }/>
            <Route path='/404' element={
                <Column spacingInset='500'>
                    <Page404/>
                </Column>
            }/>
            <Route path={RouteName.PROBLEMS} element={
                <Column spacingInset='500'>
                    <TableResourceComponent configuration={problems}/>
                </Column>
            }/>
            <Route path={RouteName.PROBLEMS + '/:id'} element={
                <Column spacingInset='500'>
                    <ViewResourceComponent configuration={problems}/>
                </Column>
            }/>
            <Route path={RouteName.PROBLEM_FINDERS} element={
                <Column spacingInset='500'>
                    <TableResourceComponent configuration={problemFinders}/>
                </Column>
            }/>
            <Route path={RouteName.PROBLEM_FINDERS + '/:id'} element={
                <Column spacingInset='500' maxWidth={maxWidth}>
                    <ViewResourceComponent configuration={problemFinders}/>
                </Column>
            }/>
            <Route path={RouteName.PROBLEM_FINDERS + '/:problemFinderId/edit'} element={
                <Column spacingInset='500' maxWidth={maxWidth}>
                    <PutProblemFinder inEditMode={true}/>
                </Column>
            }/>
            <Route path={RouteName.PROBLEM_FINDERS + '/create'} element={
                <Column spacingInset='500' maxWidth={maxWidth}>
                    <PutProblemFinder inEditMode={false}/>
                </Column>
            }/>
            <Route path={RouteName.REPORT_CONFIGURATIONS} element={
                <Column spacingInset='500'>
                    <TableResourceComponent configuration={reportConfigurations}/>
                </Column>
            }/>
            <Route path={RouteName.REPORT_CONFIGURATIONS + '/:id'} element={
                <Column spacingInset='500' maxWidth={maxWidth}>
                    <ViewResourceComponent configuration={reportConfigurations}/>
                </Column>
            }/>
            <Route path={RouteName.REPORT_CONFIGURATIONS + '/:reportConfigurationId/edit'} element={
                <Column spacingInset='500' maxWidth={maxWidth}>
                    <PutReportConfiguration inEditMode={true}/>
                </Column>
            }/>
            <Route path={RouteName.REPORT_CONFIGURATIONS + '/create'} element={
                <Column spacingInset='500' maxWidth={maxWidth}>
                    <PutReportConfiguration inEditMode={false}/>
                </Column>
            }/>
            <Route path={RouteName.PROBLEM_TYPES} element={
                <Column spacingInset='500'>
                    <TableResourceComponent configuration={problemTypes}/>
                </Column>
            }/>
            <Route path={RouteName.PROBLEM_TYPES + '/:id'} element={
                <Column spacingInset='500' maxWidth={maxWidth}>
                    <ViewResourceComponent configuration={problemTypes}/>
                </Column>
            }/>
            <Route path={RouteName.PROBLEM_TYPES + '/:id/edit'} element={
                <Column spacingInset='500' maxWidth={maxWidth}>
                    <PutResourceComponent inEditMode={true} configuration={problemTypes}/>
                </Column>
            }/>
            <Route path={RouteName.PROBLEM_TYPES + '/create'} element={
                <Column spacingInset='500' maxWidth={maxWidth}>
                    <PutResourceComponent inEditMode={false} configuration={problemTypes}/>
                </Column>
            }/>
            <Route path={RouteName.OVERRIDES} element={
                <Column spacingInset='500'>
                    <TableResourceComponent configuration={overrides}/>
                </Column>
            }/>
            <Route path={RouteName.OVERRIDES + '/:id'} element={
                <Column spacingInset='500' maxWidth={maxWidth}>
                    <ViewResourceComponent configuration={overrides}/>
                </Column>
            }/>
            <Route path={RouteName.OVERRIDES + '/:id/edit'} element={
                <Column spacingInset='500' maxWidth={maxWidth}>
                    <PutResourceComponent configuration={overrides} inEditMode={true}/>
                </Column>
            }/>
            <Route path={RouteName.OVERRIDES + '/create'} element={
                <Column spacingInset='500' maxWidth={maxWidth}>
                    <PutResourceComponent configuration={overrides} inEditMode={false}/>
                </Column>
            }/>
            <Route path={RouteName.WEBHOOKS} element={
                <Column spacingInset='500'>
                    <TableResourceComponent configuration={webhooks}/>
                </Column>
            }/>
            <Route path={RouteName.WEBHOOKS + '/create'} element={
                <Column spacingInset='500'>
                    <PutResourceComponent inEditMode={false} configuration={webhooks}/>
                </Column>
            }/>
            <Route path={RouteName.WEBHOOKS + '/:id'} element={
                <Column spacingInset='500'>
                    <ViewResourceComponent configuration={webhooks}/>
                </Column>
            }/>
            <Route path={RouteName.WEBHOOKS + '/:id/edit'} element={
                <Column spacingInset='500'>
                    <PutResourceComponent inEditMode={true} configuration={webhooks}/>
                </Column>
            }/>

            <Route path={RouteName.WEBHOOK_SETS} element={
                <Column spacingInset='500'>
                    <TableResourceComponent configuration={webhookSet}/>
                </Column>
            }/>
            <Route path={RouteName.WEBHOOK_SETS + '/create'} element={
                <Column spacingInset='500'>
                    <PutResourceComponent inEditMode={false} configuration={webhookSet}/>
                </Column>
            }/>
            <Route path={RouteName.WEBHOOK_SETS + '/:id'} element={
                <Column spacingInset='500'>
                    <ViewResourceComponent configuration={webhookSet}/>
                </Column>
            }/>
            <Route path={RouteName.WEBHOOK_SETS + '/:id/edit'} element={
                <Column spacingInset='500'>
                    <PutResourceComponent inEditMode={true} configuration={webhookSet}/>
                </Column>
            }/>
            {/* Catch all for any unknown routes */}
            <Route path='*' element={
                <Column spacingInset='500'>
                    <Page404/>
                </Column>
            }/>
        </Routes>
    );
};

export default RoutingPage;
